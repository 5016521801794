<template>
  <m-select-query
    v-model="booking"
    :label="$tc('booking.booking')"
    :query="autocomplete"
    clearable
  />
</template>

<script>
import { index } from 'api/bookings'
import { MSelectQuery } from 'components/'

export default {
  components: { MSelectQuery },
  props: ['dense', 'value'],
  data () {
    return {
      term: null
    }
  },
  computed: {
    booking: {
      get () {
        return this.value
      },
      set (booking) {
        this.$emit('input', booking)
      }
    }
  },
  methods: {
    autocomplete (string) {
      return index({
        reference: string
      })
        .then(bookings => {
          return bookings.data.map(b => ({
            label: b.reference,
            value: b.reference,
            description: b.name,
            icon: 'receipt',
            color: 'brown-5'
          }))
        })
    },
    selected (item) {
      this.$emit('input', item)
      this.$emit('change', item)
    }
  }
}
</script>

<style>

</style>
